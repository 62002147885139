import PropTypes from "prop-types"

const AppButton = ({ disabled, className, onClick, type, children, gradient = false, ...r }) => {
  return (
    <button
      disabled={disabled}
      className={`min-w-fit flex-1 md:flex-none md:min-w-[180px] ${className} capitalize h-[50px] ${
        gradient ? "bg-gradient-to-r from-[#60088C] to-[#A11E90]" : ""
      } disabled:opacity-50 rounded`}
      onClick={onClick}
      type={type || "button"}
      {...r}
    >
      <span className={`text-base font-medium ${gradient ? "text-white" : ""}`}>{children}</span>
    </button>
  )
}

AppButton.propTypes = {
  style: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  children: PropTypes.any,
  gradient: PropTypes.bool,
}

export default AppButton
